import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	type?: string;
	megaMenu?: boolean;
	image?: string;
	active?: boolean;
	badge?: boolean;
	badgeText?: string;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	constructor() { }

	public screenWidth: any;
	public leftMenuToggle: boolean = false;
	public mainMenuToggle: boolean = false;

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			title: 'Home',
			 type: 'home',
			  active: false, 
			//  children:
			//  [
			// 	{
			// 		title: 'clothing', type: 'sub', active: false, children: [
			// 			{ path: '/home/fashion', title: 'fashion-01', type: 'link' },
			// 			{ path: '/home/fashion-2', title: 'fashion-02', type: 'link' },
			// 			{ path: '/home/fashion-3', title: 'fashion-03', type: 'link' }
			// 		]

			// 	},
			// 	{ path: '/home/bibles', title: 'Bibles', type: 'link' },
			// 	{ path: '/home/covers', title: 'Bible Covers', type: 'link' },
			// 	{ path: '/home/songs', title: 'Songs Books', type: 'link' },
			// 	{ path: '/home/frames', title: 'Photo Frames', type: 'link' },
			// 	{ path: '/home/trees', title: 'Christmas Trees', type: 'link' },
			// 	{ path: '/home/clock', title: 'Wall Clocks', type: 'link' },
			// 	{ path: '/home/pets', title: 'pets', type: 'link' },
			// 	{ path: '/home/gym', title: 'gym', type: 'link' },
			// 	{ path: '/home/tools', title: 'tools', type: 'link' },
			// 	{ path: '/home/shoes', title: 'shoes', type: 'link' },
			// 	{ path: '/home/bags', title: 'bags', type: 'link' },
			// 	{ path: '/home/marijuana', title: 'marijuana', type: 'link' }
			//  ]

		},

		{
			title: 'About Us', type: 'sub', active: false, 
			// children: [
				// { path: '/home/wariors', title: 'Warrior Details', type: 'link' },
				// { path: '/home/request', title: 'Prayer-Request', type: 'link' },

				// { path: '/shop/collection/right/sidebar', title: 'right-sidebar', type: 'link' },
				// { path: '/shop/collection/no/sidebar', title: 'no-sidebar', type: 'link' },
				// { path: '/shop/collection/infinitescroll', title: 'Infinite Scroll', type: 'link' }

				//  { path: '', title: 'Company', },
				//  { path: '', title: 'Leader Ship', type: 'link' },
				//  { path: '', title: 'Vision-Mission-Values', type: 'link' },
				//  { path: '', title: 'Faith Statement', type: 'link' },
				//  { path: '', title: 'Locations', type: 'link' },
				//  { path: '', title: 'Ministries', type: 'link' },

			// ]
		},
		{
			 title: 'Services', type: 'sub', active: false, 
			  children: [
				// {
				// 	title: 'sidebar', type: 'sub', active: false, children: [
				// 		{ path: '/shop/product/left/sidebar/trim-dress', title: 'left-sidebar', type: 'link' },
				// 		{ path: '/shop/product/right/sidebar/trim-dress', title: 'right-sidebar', type: 'link' },
				// 		{ path: '/shop/product/no/sidebar/trim-dress', title: 'no-sidebar', type: 'link' }
				// 	]
				// },
				// { path: '/shop/product/three/column/trim-dress', title: 'Prayer Request', type: 'link' },
				// { path: '/shop/product/four/image/belted-dress', title: 'four-image', type: 'link' },
				// { path: '/shop/product/bundle/trim-dress', title: 'bundle-product', type: 'link' },
				// { path: '/shop/product/image/outside/trim-dress', title: 'image-outside', type: 'link' }

				// {
				//   title:'Church Ministry',type:'sub', active: false,
				//    children:[
				// 	{ path: '/pages/membership', title: 'Membership', type: 'link' },
				// 	{ path: '/pages/worship', title: 'Worship', type: 'link' },
				// 	{ path: '/pages/sermons', title: 'Sermons', type: 'link' },
				// 	{ path: '/pages/testimonies', title: 'Testimonies', type: 'link' },
				// 	{ path: '/pages/dailypromise', title: 'Daily Promise', type: 'link' },
				// 	{ path: '/pages/churchevents', title: 'Church Events', type: 'link' },
				// 	{ path: '/pages/communityservices', title: 'Community Services', type: 'link' },
				// 	{ path: '/pages/forthcomingprojects', title: 'Forth Coming Projects', type: 'link' },
				// 	{ path: '/pages/prayerrequests', title: 'Prayer Requests', type: 'link' },
				// 	{ path: '/pages/offeringstithes', title: 'Offerings Tithes', type: 'link' },
				// 	{ path: '/home/letting', title: 'Letting', type: 'link' },
				// 	{ path: '/pages/prayercells', title: 'Prayer Cells', type: 'link' },
				// 	{ path: '/pages/notifications', title: 'Notifications', type: 'link' },
				// 	{ path: '/pages/counselling', title: 'Counselling', type: 'link' },
				// 	{ path: '/pages/kidssection', title: 'Kids Section', type: 'link' },
				// 	{ path: '/pages/newvisitors', title: 'New Visitors', type: 'link' },
				// 	{ path: '/pages/funeralservices', title: 'Funeral Services', type: 'link' },
				// 	{ path: '/pages/elections', title: 'Elections', type: 'link' },
				// 	{ path: '/pages/missions', title: 'Missions', type: 'link' },

				// 	]
				// },
				// {
				// 	title:'Christian Matrimony',type:'sub', active: false, children:[
                //     { path: '/home/brides', title: 'Brides', type: 'link' },
				// 	{ path: '/home/grooms', title: 'Grooms', type: 'link' },
				// 	{ path: '/home/adverts', title: 'Adverts', type: 'link' },
				// 	{ path: '/home/fees', title: 'Fees', type: 'link' },
				// 	{ path: '/home/importance', title: 'Importance of Marriage', type: 'link' },
				// 	{ path: '/home/alligance', title: 'International Marriage Alliances', type: 'link' },
				// 	]
				// },
				// { path: '/home/business', title: 'Fiduciary Business', type: 'link' },
				// { path: '/home/employment', title: 'Skill-ED Employment', type: 'link' },
				// { path: '/home/holylandtours', title: 'Holy Land Tours', type: 'link' },
				// { path: '/home/letting', title: 'Letting', type: 'link' },

				 { path: '/home/jobportal', title: 'Job Portal', type: 'link' },
				 { path: '/matrimonials/registrations', title: 'Matrimonials', type: 'link' },
				 { path: '/admin/adminlogin', title: 'Admin Login', type: 'link' },

			 ]
		 },
		 
		// {
		// 	title: 'Gallery', type: 'sub', megaMenu: true, badge: true, badgeText: 'new', active: false, children: [
		// 		{
		// 			title: 'portfolio', type: 'sub', active: false, children: [
		// 				{ path: '/pages/portfolio/grid/two', title: 'portfolio-grid-2', type: 'link' },
		// 				{ path: '/pages/portfolio/grid/three', title: 'portfolio-grid-3', type: 'link' },
		// 				{ path: '/pages/portfolio/grid/four', title: 'portfolio-grid-4', type: 'link' },
		// 				{ path: '/pages/portfolio/masonry/grid/two', title: 'masonry-grid-2', type: 'link' },
		// 				{ path: '/pages/portfolio/masonry/grid/three', title: 'masonry-grid-3', type: 'link' },
		// 				{ path: '/pages/portfolio/masonry/grid/four', title: 'masonry-grid-4', type: 'link' },
		// 				{ path: '/pages/portfolio/masonry/full-width', title: 'masonry-Full-Width', type: 'link' }
		// 			]
		// 		},
		// 		{
		// 			title: 'add-to-cart', type: 'sub', active: false, children: [
		// 				{ path: '/home/vegetable', title: 'cart-right', type: 'link' },
		// 				{ path: '/home/watch', title: 'cart-left', type: 'link' },
		// 				{ path: '/home/furniture', title: 'cart-top', type: 'link' },
		// 				{ path: '/home/flower', title: 'cart-bottom', type: 'link' },
		// 				{ path: '/home/fashion', title: 'cart-model-popup', type: 'link' }
		// 			]
		// 		},
		// 		{
		// 			title: 'theme-elements', type: 'sub', active: false, children: [
		// 				{ path: '/elements/theme/title', title: 'title', type: 'link' },
		// 				{ path: '/elements/theme/collection-banner', title: 'collection-banner', type: 'link' },
		// 				{ path: '/elements/theme/home-slider', title: 'home-slider', type: 'link' },
		// 				{ path: '/elements/theme/category', title: 'category', type: 'link' },
		// 				{ path: '/elements/theme/services', title: 'services', type: 'link' }
		// 			]
		// 		},
		// 		{
		// 			title: 'product-elements', type: 'sub', active: false, children: [
		// 				{ path: '/elements/product/slider', title: 'product-slider', type: 'link' },
		// 				{ path: '/elements/product/banners', title: 'banners', type: 'link' },
		// 				{ path: '/elements/product/tabs', title: 'product-tabs', type: 'link' },
		// 				{ path: '/elements/product/multi-slider', title: 'multi-slider', type: 'link' }
		// 			]
		// 		},
		// 		{
		// 			title: 'email-template', type: 'sub', active: false, children: [
		// 				{ path: 'http://themes.pixelstrap.com/multikart/front-end/email-order-success.html', title: 'order-success', type: 'extTabLink' },
		// 				{ path: 'http://themes.pixelstrap.com/multikart/front-end/email-order-success-two.html', title: 'order-success-2', type: 'extTabLink' },
		// 				{ path: 'http://themes.pixelstrap.com/multikart/front-end/email-template.html', title: 'email-template', type: 'extTabLink' },
		// 				{ path: 'http://themes.pixelstrap.com/multikart/front-end/email-template-two.html', title: 'email-template-2', type: 'extTabLink' }
		// 			]
		// 		}
		// 	]
		// },


         {
		title:'Gallery',type:'sub', active:false,
		// children:[
		// { path: '/home/audio', title: 'Audio', type: 'link' },
		// { path: '/home/photo', title: 'Photo', type: 'link' },
		// { path: '/home/videos', title: 'Video', type: 'link' },
			// ]
		 },

		{
			// title: 'Blog', type: 'sub', active: false,
			//  children: [
				// {
				// 	title: 'account', type: 'sub', active: false, children: [
				// 		{ path: '/pages/wishlist', title: 'wishlist', type: 'link' },
				// 		{ path: '/pages/cart', title: 'cart', type: 'link' },
				// 		 { path: '/pages/dashboard', title: 'dashboard', type: 'link' },
				// 		{ path: '/pages/login', title: 'login', type: 'link' },
				// 		{ path: '/pages/register', title: 'register', type: 'link' },
				// 		{ path: '/pages/contact', title: 'contact', type: 'link' },
				// 		{ path: '/pages/forget/password', title: 'forget-password', type: 'link' },
				// 		{ path: '/pages/profile', title: 'profile', type: 'link' },
				// 		{ path: '/pages/checkout', title: 'checkout', type: 'link' },
				// 	]
				// },
				// { path: '/pages/aboutus', title: 'about-us', type: 'link' },
				// { path: '/pages/search', title: 'search', type: 'link' },
				// { path: '/pages/typography', title: 'typography', type: 'link', badge: true, badgeText: 'new' },
				// { path: '/pages/review', title: 'review', type: 'link', badge: true, badgeText: 'new' },
				// { path: '/pages/order/success', title: 'order-success', type: 'link' },
				// 	{ 
				// 		title: 'compare', type: 'sub', active: false, children: [
				// 			{ path: '/pages/compare/one', title: 'compare-1', type: 'link' },
				// 			{ path: '/pages/compare/two', title: 'compare-2', type: 'link', badge: true, badgeText: 'new' }
				// 		]
				// 	},
				// { path: '/pages/collection', title: 'collection', type: 'link' },
				// { path: '/pages/lookbook', title: 'lookbook', type: 'link' },
				// { path: '/pages/404', title: '404', type: 'link' },
				// { path: '/pages/comingsoon', title: 'coming-soon', type: 'link', badge: true, badgeText: 'new' },
				// { path: '/pages/faq', title: 'faq', type: 'link' }

			// { path: '/home/articles', title: 'Articles', type: 'link' },
			// { path: '/home/reviewes', title: 'Reviews', type: 'link' },
		// ]
		},
		{
			title: 'Contact Us', type: 'contact', active: false, 
			// children: [
				// { path: '/pages/blog/left/sidebar', title: 'left-sidebar', type: 'link' },
				// { path: '/pages/blog/right/sidebar', title: 'right-sidebar', type: 'link' },
				// { path: '/pages/blog/no/sidebar', title: 'no-sidebar', type: 'link' },
				// { path: '/pages/blog/details', title: 'blog-details', type: 'link' }

				// { path: '/home/contactform', title: 'Contact Form', type: 'link' },

			// ]
		},
		{
			title: 'Donate Now', type: 'sub', active: false,
			//  children: [
				// { path: '/pages/blog/left/sidebar', title: 'left-sidebar', type: 'link' },
				// { path: '/pages/blog/right/sidebar', title: 'right-sidebar', type: 'link' },
				// { path: '/pages/blog/no/sidebar', title: 'no-sidebar', type: 'link' },
				// { path: '/pages/blog/details', title: 'blog-details', type: 'link' }

				// { path: '/home/bankdetails', title: 'Bank Details', type: 'link' },
				// { path: '/home/internetbanking', title: 'Internet Banking', type: 'link' },
				// { path: '/home/creditcard', title: 'Credit Card Payment',type: 'link' },
				// { path: '/home/upipayment', title: 'UPI / BHIM', type: 'link' },
				
			// ]
		}
	];

	LEFTMENUITEMS: Menu[] = [
		{
			title: 'Home', type: 'sub', megaMenu: true, active: false,
			//  children: [
			//   {
			// 	  title: 'mens fashion',  type: 'link', active: false, children: [
			// 		  { path: '/home/fashion', title: 'sports wear',  type: 'link' },
			// 		  { path: '/home/fashion', title: 'top',  type: 'link' },
			// 		  { path: '/home/fashion', title: 'bottom',  type: 'link' },
			// 		  { path: '/home/fashion', title: 'ethic wear',  type: 'link' },
			// 		  { path: '/home/fashion', title: 'sports wear',  type: 'link' },
			// 		  { path: '/home/fashion', title: 'shirts',  type: 'link' },
			// 		  { path: '/home/fashion', title: 'bottom',  type: 'link' },
			// 		  { path: '/home/fashion', title: 'ethic wear',  type: 'link' },
			// 		  { path: '/home/fashion', title: 'sports wear',  type: 'link' }
			// 	  ]
			//   },
			//   {
			// 	  title: 'women fashion',  type: 'link', active: false, children: [
			// 		  { path: '/home/fashion', title: 'dresses',  type: 'link' },
			// 		  { path: '/home/fashion', title: 'skirts',  type: 'link' },
			// 		  { path: '/home/fashion', title: 'westarn wear',  type: 'link' },
			// 		  { path: '/home/fashion', title: 'ethic wear',  type: 'link' },
			// 		  { path: '/home/fashion', title: 'bottom',  type: 'link' },
			// 		  { path: '/home/fashion', title: 'ethic wear',  type: 'link' },
			// 		  { path: '/home/fashion', title: 'sports wear',  type: 'link' },
			// 		  { path: '/home/fashion', title: 'sports wear',  type: 'link' },
			// 		  { path: '/home/fashion', title: 'bottom wear',  type: 'link' }
			// 	  ]
			//   },
			// ]
		},
		{
			title: 'About Us', type: 'sub', active: false, children: [
			//   { path: '/home/fashion', title: 'shopper bags', type: 'link' },
			//   { path: '/home/fashion', title: 'laptop bags', type: 'link' },
			//   { path: '/home/fashion', title: 'clutches', type: 'link' },
			//   {
			// 	  path: '/home/fashion', title: 'purses', type: 'link', active: false, children: [
			// 		  { path: '/home/fashion', title: 'purses',  type: 'link' },
			// 		  { path: '/home/fashion', title: 'wallets',  type: 'link' },
			// 		  { path: '/home/fashion', title: 'leathers',  type: 'link' },
			// 		  { path: '/home/fashion', title: 'satchels',  type: 'link' }
			// 	  ]
			//   },
			]
		},
		{
			title: 'Services', type: 'sub', active: false,
			//  children: [
			//   { path: '/home/fashion', title: 'Bangaru Ghantalu', type: 'link' },
			//   { path: '/home/fashion', title: 'Andhra christhava', type: 'link' },
			//   { path: '/home/fashion', title: 'casual shoes', type: 'link' }
			// ]
		},
		{
			path: '/home/fashion', title: 'watches', type: 'link'
		},
		{
			title: 'Gallery', type: 'sub', active: false, children: [
			//   { path: '/home/fashion', title: 'fashion jewellery', type: 'link' },
			//   { path: '/home/fashion', title: 'caps and hats', type: 'link' },
			//   { path: '/home/fashion', title: 'precious jewellery', type: 'link' },
			//   {
			// 	  path: '/home/fashion', title: 'more..', type: 'link', active: false, children: [
			// 		  { path: '/home/fashion', title: 'necklaces',  type: 'link' },
			// 		  { path: '/home/fashion', title: 'earrings',  type: 'link' },
			// 		  { path: '/home/fashion', title: 'rings & wrist wear',  type: 'link' },
			// 		  {
			// 			  path: '/home/fashion', title: 'more...',  type: 'link', active: false, children: [
			// 				  { path: '/home/fashion', title: 'ties',  type: 'link' },
			// 				  { path: '/home/fashion', title: 'cufflinks',  type: 'link' },
			// 				  { path: '/home/fashion', title: 'pockets squares',  type: 'link' },
			// 				  { path: '/home/fashion', title: 'helmets',  type: 'link' },
			// 				  { path: '/home/fashion', title: 'scarves',  type: 'link' },
			// 				  {
			// 					  path: '/home/fashion', title: 'more...',  type: 'link', active: false, children: [
			// 						  { path: '/home/fashion', title: 'accessory gift sets',  type: 'link' },
			// 						  { path: '/home/fashion', title: 'travel accessories',  type: 'link' },
			// 						  { path: '/home/fashion', title: 'phone cases',  type: 'link' }
			// 					  ]
			// 				  },
			// 			]
			// 		  }
			// 	  ]
			//   },
			]
		},
		{
			path: '/home/fashion', title: 'Blog', type: 'link'
		},
		{
			title: 'Contact Us', type: 'contact', active: false, children: [
			//   { path: '/home/fashion', title: 'makeup', type: 'link' },
			//   { path: '/home/fashion', title: 'skincare', type: 'link' },
			//   { path: '/home/fashion', title: 'premium beaty', type: 'link' },
			//   {
			// 	  path: '/home/fashion', title: 'more..', type: 'link', active: false, children: [
			// 		  { path: '/home/fashion', title: 'fragrances',  type: 'link' },
			// 		  { path: '/home/fashion', title: 'luxury beauty',  type: 'link' },
			// 		  { path: '/home/fashion', title: 'hair care',  type: 'link' },
			// 		  { path: '/home/fashion', title: 'tools & brushes',  type: 'link' }
			// 	  ]
			//   },
			]
		},
		{
			path: '/home/fashion', title: 'Donate Now', type: 'link'
		},
		// {
		// 	path: '/home/fashion', title: 'Donate Now', type: 'link'
		// },


		{
			path: '/matrimonials/registrations', title: 'Matrimonials', type: 'link'
		},
		{
			path: '/matrimonials/register', title: 'Admin-Register', type: 'link'
		},
		{
			path: '/matrimonials/change', title: 'Change Password', type: 'link'
		},
		{
			path: '/matrimonials/forgot', title: 'Forgot Password', type: 'link'
		},
		{
			path: '/matrimonials/vendour', title: 'Vendour Profile', type: 'link'
		},
		{
			path: '/matrimonials/subscriptions', title: 'Subscriptions', type: 'link'
		},
		{
			path: '/matrimonials/payments', title: 'Payments', type: 'link'
		},
		{
			path: '/matrimonials/bride', title: 'Bride-Registration-Form', type: 'link'
		},
		{
			path: '/shop/bride-filter', title: 'Bride-Filter', type: 'link'
		},
		{
			path: '/pages/dashboard-church', title: 'Dashboard-Church', type: 'link'
		},
		{
			path: '/matrimonials/charts', title: 'Charts', type: 'link'
		},
		{
			path: '/matrimonials/profilepics', title: 'Profile Pics', type: 'link'
		},
		{
			path: '/matrimonials/vendorFilters', title: 'Vendor Filters', type: 'link'
		},
		{
			path: '/matrimonials/candidateProfile', title: 'Candidate Profile', type: 'link'
		},
		{
			path: '/home/jobportal', title: 'Job Portals', type: 'link'
		},
		{
			path: '/matrimonials/validate-candidate-email', title: 'Email Success', type: 'link'
		},
		
	];
	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	leftMenuItems = new BehaviorSubject<Menu[]>(this.LEFTMENUITEMS);
}
