<!--footer section -->
<footer [ngClass]="class">
    <div class="dark-layout" *ngIf="mainFooter">
        <div class="container">
            <section class="section-b-space border-b">
                <div class="row footer-theme2">
                    <div class="col-lg-3">
                        <div class="footer-title footer-mobile-title">
                            <h4>about</h4>
                        </div>
                        <div class="footer-contant">
                            <div class="footer-logo" id="footerlogo">
                                <img [src]="themeLogo"  alt="logo">
                            </div>
                            <p>About Our Church
                                We welcome visitors at NewLife Church and would love to have you join us in church this weekend.<br>
                                Email: info@gethsemaneprayers.com</p>
                        </div>
                    </div>
                    <div class="col-lg-6 subscribe-wrapper">
                        <div class="subscribe-block">
                            <h2>newsletter</h2>
                            <form >
                                <div class="form-group">
                                    <input type="text" class="form-control" id="exampleFormControlInput3" placeholder="Enter your email">
                                    <button type="submit" class="btn btn-solid ">subscribe</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="footer-title">
                            <h4>Church Information</h4>
                        </div>
                        <div class="footer-contant">
                            <ul class="contact-list">
						        <li><i class="fa fa-map-marker"></i>Gesthemane Prayer House</li>
						        <li><i class="fa fa-phone"></i>Call Us: 123-456-7898</li>
						        <li><i class="fa fa-envelope-o"></i>Email Us: <a>info@gethsemaneprayers.com</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <div class="dark-layout" *ngIf="subFooter">
        <div class="container">
            <section class="small-section">
                <div class="row footer-theme2">
                    <div class="col p-set">
                        <div class="footer-link">
                            <div class="sub-title">
								<div class="footer-title">
									<h4>Categories</h4>
								</div>
								<div class="footer-contant">
									<ul>
                                        <li><a href="javascript:void(0)">Men & Womens Fellowship</a></li>
                                        <li><a href="javascript:void(0)">children Sunday School</a></li>
                                        <li><a href="javascript:void(0)"></a>Group Prayers</li>
                                        <li><a href="javascript:void(0)"></a>Pasting Prayers</li>
                                    </ul>
								</div>
							</div>
                        </div>
                        <div class="footer-link-b">
                            <div class="sub-title">
								<div class="footer-title">
									<h4>why we choose</h4>
								</div>
								<!-- <div class="footer-contant">
									<ul>
										<li><a href="javascript:void(0)">shipping & return</a></li>
										<li><a href="javascript:void(0)">secure shopping</a></li>
										<li><a href="javascript:void(0)">gallary</a></li>
										<li><a href="javascript:void(0)">affiliates</a></li>
										<li><a href="javascript:void(0)">contacts</a></li>
									</ul>
								</div> -->
							</div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <div class="sub-footer darker-subfooter">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="footer-end">
                    <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y' }} Gesthemane Prayer House</p>
                  </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="payment-card-bottom">
                    <ul>
                      <li>
                        <a href="javascript:void(0)">
                            <img src="assets/images/icon/visa.png" alt="">
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                            <img src="assets/images/icon/mastercard.png" alt="">
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                            <img src="assets/images/icon/paypal.png" alt="">
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                            <img src="assets/images/icon/american-express.png" alt="">
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                            <img src="assets/images/icon/discover.png" alt="">
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!--footer section end -->