// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// http://178.16.138.75:3001/api_node/v1/countryMaster/getCountries
// api_node/v1/users/emailVerification?verify_key=123
// http://localhost:3001/api_node/v1/subscribe/createSubscription
// http://localhost:3001/api_node/v1/users/forgotPassword

export const api={
  PORT: 3001,
   HOST_URI: 'http://178.16.138.75:3001/',
  // HOST_URI: 'http://localhost:3001/',
  URI_PREFIX: 'api_node/v1/',
  
};
 
export const environment = {
  production: false,
  stripe_token: 'STRIPE_TOKEN',
  paypal_token: 'PAYPAL_TOKEN',
  CANDIDATE_APIS:{
    createProfile:api.HOST_URI+api.URI_PREFIX+"candidateResumes/createProfile",
     getCountries:api.HOST_URI+api.URI_PREFIX+"countryMaster/getCountries",
     emailVerification:api.HOST_URI+api.URI_PREFIX+"users/emailVerification",
     createSubscription:api.HOST_URI+api.URI_PREFIX+"subscribe/createSubscription",
     forgotPassword:api.HOST_URI+api.URI_PREFIX+"users/forgotPassword"

  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
