import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input, AfterViewInit,
  Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CandidateService } from 'src/app/services/candidate.service';
import { FormGroup, FormBuilder, Validators, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild("newsletter", { static: false }) NewsLetterModal: TemplateRef<any>;
  @ViewChild("christmas", { static: false }) ChristmasModel: TemplateRef<any>;

  public closeResult: string;
  public modalOpen: boolean = false;
  
  form:FormGroup;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,private candidateService:CandidateService,private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      });

  }

  ngAfterViewInit(): void {
    // if(localStorage.getItem("newsletter") !== 'true')
      //  this.openModal();
       this.openModalChristmas();

    // localStorage.setItem("newsletter", 'true');
  }

  openModal() {
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.NewsLetterModal, { 
        size: 'lg',
        ariaLabelledBy: 'NewsLetter-Modal',
        centered: true,
        windowClass: 'theme-modal newsletterm NewsLetterModal'
      }).result.then((result) => {
        this.modalOpen = true;
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  openModalChristmas() {
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.ChristmasModel, { 
        size: 'lg',
        ariaLabelledBy: 'NewsLetter-Modal',
        centered: true,
        windowClass: 'theme-modal newsletterm NewsLetterModal'
      }).result.then((result) => {
        this.modalOpen = true;
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if(this.modalOpen){
      this.modalService.dismissAll();
    }
  }
  createSubscription() {

    // console.log("selectedItem", this.selectedItem)
    

    let reqObj = {
      "email": this.form.value["email"],
     
    }
    console.log("Form", this.form.value)
    console.log("reqObj", reqObj)
// return;
    this.candidateService.createSubscription(reqObj).subscribe((resp:any)=>{
      console.log("Rec profile update success", resp);

      if(resp.statusCode ==200){

        this.form.reset();
        this.modalService.dismissAll();
        // this.errorStatus =true;
        // this.errorMsg = resp.supportMessage;
        // this.snackBarSimpleComponent.openSnackBar(resp.supportMessage,"","center","top",3);
        
      }
    });
  }
}
