<h2 mat-dialog-title >Count Blessings</h2>
<mat-form-field appearance="outline">
  <mat-label >Year</mat-label>
  <mat-select [(ngModel)]="yearsObj">
    <mat-option *ngFor="let yearsObj of years" [value]="yearsObj">{{yearsObj}}</mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field appearance="outline">
<mat-label>Months</mat-label>
  <mat-select [(ngModel)]="monthsObj">
    <mat-option *ngFor="let monthsObj of months" [value]="monthsObj">{{monthsObj}}</mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field appearance="outline">
  <mat-label>Description</mat-label>
  <textarea matInput></textarea>
</mat-form-field>
<mat-dialog-actions >
  <button mat-button mat-dialog-close >Save & Close</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Close</button>
</mat-dialog-actions>