import { Component, OnInit, Input } from '@angular/core';
import { CandidateService } from 'src/app/services/candidate.service';
import { FormGroup, FormBuilder, Validators, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {

  @Input() class: string = 'footer-light' // Default class 
   @Input() themeLogo: string = 'assets/images/logos/logo-gp-text.png' // Default Logo
  // @Input() themeLogo: string = 'assets/images/icon/logo.png'
  @Input() newsletter: boolean = true; // Default True
  form:FormGroup;

  public today: number = Date.now();

  constructor(private candidateService:CandidateService,private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      });
  }
  createSubscription() {

    // console.log("selectedItem", this.selectedItem)
    

    let reqObj = {
      "email": this.form.value["email"],
     
    }
    console.log("Form", this.form.value)
    console.log("reqObj", reqObj)
// return;
    this.candidateService.createSubscription(reqObj).subscribe((resp:any)=>{
      console.log("Rec profile update success", resp);

      if(resp.statusCode ==200){
        // this.form.value['email']="";
        this.form.reset();
        // this.errorStatus =true;
        // this.errorMsg = resp.supportMessage;
        // this.snackBarSimpleComponent.openSnackBar(resp.supportMessage,"","center","top",3);
        
      }
    });
  }

}
