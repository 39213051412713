import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-small-dialog',
  templateUrl: './small-dialog.component.html',
  styleUrls: ['./small-dialog.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class SmallDialogComponent {
//   data:any;
name ='sssss';
  constructor(
    public dialogRef: MatDialogRef<SmallDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      console.log("Dialod Data::",data)
      // this.data =dialogData;
    }

  onNoClick(): void {
    console.log("close clicked...")
    this.data.submit_btn_clicked=false;
    this.dialogRef.close(this.data);
  }

  ngOnInit(): void {
    console.log("this Dialod Data::",this.data)
  }
}
