import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CandidateService {

  constructor(private http: HttpClient, private api: ApiService) {}

  createProfile(data:any){
    return this.api.POST_BR(environment.CANDIDATE_APIS.createProfile, data);
}
 getCountries(data:any){
  return this.api.GET_BR(environment.CANDIDATE_APIS.getCountries, data);
}
emailVerification(data:any){
  let key=data.verifyKey
  return this.api.GET_BR(environment.CANDIDATE_APIS.emailVerification+"?verify_key="+key, data);
}

createSubscription(data:any){
  return this.api.POST_BR(environment.CANDIDATE_APIS.createSubscription, data);
}

forgotPassword(data:any){
  return this.api.POST_BR(environment.CANDIDATE_APIS.forgotPassword, data);
}

}
