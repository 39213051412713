import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-dialog-count-blessings',
  templateUrl: './dialog-count-blessings.component.html',
  styleUrls: ['./dialog-count-blessings.component.scss'],
})
export class DialogCountBlessingsComponent {
  name: string = '';
  email: string = '';
  yearsObj: string = '';
  years: string[] = ['2021', '2022', '2023', '2024','2025','2026'];

  monthsObj: string = '';
  months: string[] = ['January', 'February', 'March', 'April','May','June','July','August','September','October','November','December'];

  constructor(public dialogRef: MatDialogRef<DialogCountBlessingsComponent>) { }

  ngOnInit() {
  }
  

}

