import { Component } from '@angular/core';

@Component({
  selector: 'app-matrimonials',
  templateUrl: './matrimonials.component.html',
  styleUrls: ['./matrimonials.component.scss']
})
export class MatrimonialsComponent {

}
