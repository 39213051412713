
<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <p>{{data.caption}}</p>
  <p>{{data.message}}</p>
</div>
<div mat-dialog-actions align="end">
  <button *ngIf="data.close_btn!=''" mat-raised-button color="primary" (click)="onNoClick()">{{data.close_btn}}</button>
  <!-- <button mat-raised-button color="primary" [mat-dialog-close]="data" cdkFocusInitial>{{data.submit_btn}}</button> -->
  <button mat-raised-button color="primary" cdkFocusInitial>{{data.submit_btn}}</button>
</div>

