<!--modal popup start-->
<ng-template class="theme-modal" #newsletter let-modal>
    <div class="modal-content">
        <div class="modal-body modal7">
            <div class="container-fluid p-0">
                <div class="row">
                    <div class="col-12">
                        <div class="modal-bg">
                            <button type="button" class="close"  aria-label="Close" (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div class="offer-content"><img src="assets/images/news.jpeg" class="img-fluid" alt="">
                                <h2>newsletter</h2>
                                <form [formGroup]="form" (ngSubmit)="createSubscription()"  class="auth-form needs-validation" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank">
                                    <div class="form-group mx-sm-3">
                                        <input type="text" class="form-control"            formControlName="email"
                                        name="EMAIL" id="mce-EMAIL" placeholder="Enter your email" required="required">
                                        <button type="submit" class="btn btn-solid" id="mc-submit">subscribe</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!--modal popup end-->



<!--Christmas Start-->
<ng-template class="theme-modal" #christmas let-modal>
    <div>
        <img src="assets/images/news.jpeg" class="img-fluid1" width="900px" height="400px" alt=""(click)="modal.dismiss('Cross click')" (mouseleave)="modal.dismiss('Cross click')">
        
    </div> 
    <!-- <div class="modal-content">
        <div class="modal-body modal7">
            <div class="container-fluid p-0">
                <div class="row">
                    <div class="col-12">
                        <div class="modal-bg">
                            <button type="button" class="close"  aria-label="Close" (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div class="offer-content">
                            <img src="assets/images/news.jpeg" class="img-fluid" width="800px" height="400px" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</ng-template>
<!--modal popup end-->



