// import { Injectable } from '@angular/core';

// @Injectable({
//   providedIn: 'root'
// })
// export class ApiService {

//   constructor() { }
// }

import { Injectable, Component, Inject, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
// import { environment } from './environment';
// import { map,catchError,Observable, throwError  } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgIf } from '@angular/common';
import { SmallDialogComponent } from './feature/small-dialog/small-dialog.component';
// import { SmallDialogComponent } from 'src/app/feature/small-dialog/small-dialog.component';

@Injectable({
  providedIn: "root",
})
export class ApiService {

  constructor(private http: HttpClient, public dialog: MatDialog) { }
  // homeurl = environment.SERVICE_APIS.landing;
  //loginurl = 
  //homeurl =this.url + 'landing';
  //jobseeker_register=environment.SERVICE_APIS.JOBSEEKER_REGISTER;
  //jobseeker_login=environment.SERVICE_APIS.JOBSEEKER_LOGIN;

  createAuthorizationHeader(): HttpHeaders {
    const headers = new HttpHeaders()
    headers.append('Access-Control-Allow-Origin', 'http://localhost:4200');
    headers.append('Access-Control-Allow-Credentials', 'true');
    // .Set('Content-Type', 'application/json'),
    // .Set('Access-Control-Allow-Origin': 'http://localhost:4200'),
    // .set('Access-Control-Allow-Methods: GET, POST, OPTIONS')
    // .set("Access-Control-Allow-Headers: Content-Type, Authorization")
    // .set('Api-Key', 'xxx');
    return headers;
  }

  get(url: any) {
    this.createAuthorizationHeader();
    return this.http.get(url, {
      headers: this.createAuthorizationHeader()
    });
  }

  post(url: any, data: {}) {
    console.log("URL::", url)
    console.log("API REQ::", data)
    return this.http.post(url, data, {
      headers: this.createAuthorizationHeader()
    });
  }
  POST_BR(url: any, req: {}) {
    // this.openDialog('1500ms', '1500ms');
    // this.errorDialog('ddddddddddddddddddd');
    console.log("URL::", url)
    console.log("API REQ::", req)
    return this.http.post(url, req, {
      // headers: this.createAuthorizationHeader()
    }).pipe(
      map((response: any) => {
        console.log(response)
        return this.setDataResp(response);
        // if (response.statusCode == 200) {
        //   return response;
        // }
        // Process the response data as needed

      })
      ,
      catchError((error: any) => {
        // Handle errors here
        console.log("Exception Err:", error)
        console.log("Exception Err:", error.error.statusCode)
        return error;
      })

    );
  }
  GET_BR(url: any, req: {}) {
    // this.openDialog('1500ms', '1500ms');
    // this.errorDialog('ddddddddddddddddddd');
    console.log("URL::", url)
    console.log("API REQ::", req)
    return this.http.get(url,  {
      // headers: this.createAuthorizationHeader()
    }).pipe(
      map((response: any) => {
        console.log(response)
        return this.setDataResp(response);
        // if (response.statusCode == 200) {
        //   return response;
        // }
        // Process the response data as needed

      })
      ,
      catchError((error: any) => {
        // Handle errors here
        console.log("Exception Err:", error)
        console.log("Exception Err:", error.error.statusCode)
        return error;
      })

    );
  }
  DELETE_BR(url: any, req: {}) {
    // this.openDialog('1500ms', '1500ms');
    // this.errorDialog('ddddddddddddddddddd');
    console.log("URL::", url)
    console.log("API REQ::", req)
    return this.http.delete(url,  {
      // headers: this.createAuthorizationHeader()
    }).pipe(
      map((response: any) => {
        console.log(response)
        return this.setDataResp(response);
        // if (response.statusCode == 200) {
        //   return response;
        // }
        // Process the response data as needed

      })
      ,
      catchError((error: any) => {
        // Handle errors here
        console.log("Exception Err:", error)
        console.log("Exception Err:", error.error.statusCode)
        return error;
      })

    );
  }
  queryparams(url: any, data: {}) {
    return this.http.post(url + '?search_key=' + data, {
      headers: this.createAuthorizationHeader()
    });
  }

  setDataResp(data: any) {
    // console.log("setDataResp:::",data);

    if (data['statusCode'] == 200) {
      // console.log("setDataResp data:::",data['data']);
      return data;
    } else {

      console.log("EE::", data)
      this.errorDialog(data['statusMessage'],data['supportMessage']);
      return false;
    }
  }





  // =============dialog============

  successDialog(data: any): void {
    let dialogRef = this.dialog.open(SmallDialogComponent, data);

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      // if(result.submit_btn_clicked && result.submit_btn_clicked!=undefined){
      // // this.deleteCompany(result.data);
      // }


      // this.animal = result;
    });
  }

  errorDialog(statusMessage:any,supportMessage: any): void {
    let dialogRef = this.dialog.open(SmallDialogComponent, {
      width: '80%',
      data: { title: statusMessage, caption: ``, message: supportMessage, close_btn: '', submit_btn: 'Ok', submit_btn_clicked: false }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      // if(result.submit_btn_clicked && result.submit_btn_clicked!=undefined){
      // // this.deleteCompany(result.data);
      // }


      // this.animal = result;
    });
  }

}




