
<!-- themeLogo -->
<!--footer section -->
<footer [class]="class">
	<div class="light-layout" *ngIf="newsletter">
		<div class="container">
			<section class="small-section border-section border-top-0">
				<div class="row">
					<div class="col-lg-6">
						<div class="subscribe">
							<div>
								<h4>KNOW IT ALL FIRST!</h4>
								<p>Never Miss Anything From Gethsemane Prayers By Signing Up To Our Newsletter. </p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<form class="form-inline subscribe-form" [formGroup]="form" (ngSubmit)="createSubscription()"  target="_blank">
							<div class="form-group mx-sm-3">
								<input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control" 
								formControlName="email" placeholder="Enter your email">
							</div>
							<button type="submit" class="btn btn-solid">subscribe</button>
						</form>
					</div>
				</div>
			</section>
		</div>
	</div>
	<section class="section-b-space light-layout">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-4 col-md-6">
					<div class="footer-title footer-mobile-title">
						<h4>about</h4>
					</div>
					<div class="footer-contant">
						<div class="footer-logo">
							<img [src]="themeLogo" alt="logo" width="179" height="60">
						</div>
						<p>About Our Church
							We welcome visitors at NewLife Church and would love to have you join us in church this weekend.<br>
							Email: info@gethsemaneprayers.com</p><div class="footer-social">
                            <!-- <ul>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-google-plus" aria-hidden="true"></i></a>                 
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-rss" aria-hidden="true"></i></a>
                                </li>
                            </ul> -->
                        </div>
					</div>
				</div>
				<div class="col offset-xl-1">
					<div class="sub-title">
						<div class="footer-title">
                            <h4>Categories</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <!-- <li><a href="javascript:void(0)">Men & Womens Fellowship</a></li>
                                <li><a href="javascript:void(0)">children Sunday School</a></li>
                                <li><a href="javascript:void(0)"></a>Group Prayers</li>
                                <li><a href="javascript:void(0)"></a>Pasting Prayers</li> -->
								<li>Men & Womens Fellowship</li>
                                <li>children Sunday School</li>
                                <li>Group Prayers</li>
                                <li>Pasting Prayers</li>
								
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
                            <h4>Church Information</h4>
                        </div>
                        <div class="footer-contant">
                            <ul class="contact-list">
						        <li><i class="fa fa-map-marker"></i>Gethsemane Prayers</li>
						        <li><i class="fa fa-phone"></i>Call Us: <a href="tel:+919849848195">+91 984 984 8195</a></li>
						        <li><i class="fa fa-envelope-o"></i>Email Us: <a href="mailto:info@gethsemaneprayers.com">info@gethsemaneprayers.com</a></li>
						    </ul>
                        </div>
					</div>
				</div>
				<!-- <div class="col">
					<div class="sub-title">
						<div class="footer-title">
						    <h4>store information</h4>
						</div>
						<div class="footer-contant">
						    <ul class="contact-list">
						        <li><i class="fa fa-map-marker"></i>Gethsemane Prayers Demo Store, Demo store
						            India 345-659</li>
						        <li><i class="fa fa-phone"></i>Call Us: 123-456-7898</li>
						        <li><i class="fa fa-envelope-o"></i>Email Us: <a>Support@Fiot.com</a></li>
						        <li><i class="fa fa-fax"></i>Fax: 123456</li>
						    </ul>
						</div>
					</div>
				</div> -->
			</div>
		</div>
	</section>
	<div class="sub-footer">
		<div class="container">
			<div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="footer-end">
                    <p><i class="fa fa-copyright" aria-hidden="true"></i> 2020-2023 Gethsemane Prayers All Rights Reserved</p>
                  </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="payment-card-bottom">
                    <!-- <ul>
                      <li>
                        <a><img src="assets/images/icon/visa.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/mastercard.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/paypal.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/american-express.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/discover.png" alt=""></a>
                      </li>
                    </ul> -->
                  </div>
                </div>
            </div>
		</div>
	</div>
</footer>
<!--footer section end -->